<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">RASCAL<br>
                </font><span class="style6">(<u>RA</u>diometric <u>S</u>elf <u>CAL</u>ibration)</span></div></td>
          </tr>
          <tr>
            <td valign="baseline">              <div align="left">RASCAL is a radiometric self-calibration method that computes the radiometric properties of an imagjng system from images of an arbitrary scene taken using different exposure settings. The images and rough exposure settings (avilable from, say, the aperture dial of a camera) are used to compute the radiometric response function of each color channel, the precise exposure values and a single high dynamic range radiance (linear) image. This page includes a detailed description of RASCAL, downloadable software, and a gallery of computed high dynamic range images.</div> 
            <p align="center"><span class="style1"><router-link to="/repository/RASCAL/about"><br>
              About Radiometric Self Calibration</router-link></span></p>
            <!-- <p align="center"><span class="style1"><a href="rrpublication.php">Publications</a></span></p> -->
            <p align="center"><span class="style1"><router-link to="/repository/RASCAL/gallery">Interactive Demo</router-link></span></p>
            <p align="center" class="style1"><router-link to="/repository/RASCAL/software">RASCAL Software</router-link> <br>
            </p></td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center"><span class="style1">              </span>
              <div align="left"><span class="style1">                </span></div>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p><span class="style1">last updated :
                      <!--webbot bot="Timestamp" startspan
s-type="EDITED" s-format="%m/%d/%y" -->
                      07/29/99
                      <!--webbot
bot="Timestamp" i-checksum="14106" endspan -->
                  </span></p>
                  <!-- <p><span class="style1">[<a href="http://www1.cs.columbia.edu/CAVE/">Goto CAVE</a>] -->
                    <!--webbot
bot="Timestamp" i-checksum="14106" endspan -->
                  <!-- </span></p> -->
                  </div>
            </div></td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>